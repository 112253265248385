@import url("https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,700");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  font-size: 6px;
  z-index: 0;
  font-family: var(--font1);
  position: relative;

  /* COLORS */

  --dark: #101113;
  --dark4: #17191d;
  --dark3: #202226;
  --dark2: #27292e;
  --dark1: #36383f;
  --light3: #494b50;
  --light2: #82848e;
  --light1: #ced0dd;
  --white: #eef0ff00;
  --whiteBg: #eef0ff;
  --gradientButton: linear-gradient(180deg, #5b48d1, #6784ff);
  --gradient: linear-gradient(180deg, #424449, #232529);
  --gradientLight: linear-gradient(180deg, #e7eaf0, #c3c9d4);
  --gradientLight2: linear-gradient(179.9deg, #c8cace 0.09%, #a2abbd 125.59%);
  --gradient2: linear-gradient(179.9deg, #17191d 0.09%, #101113 125.59%);

  /* FONTS */
  --font1: "Work Sans";
  --font2: "Space Mono";

  /* BREAKPOINTS */
  --tabletBreakpoint: 899px;
  --smallLaptopBreakpoint: 1299px;
  --largeScreenBreakpoint: 1919px;

  /* TRANSITIONS */

  --transition: all 200ms ease-in-out;
}

/* body {
	height: 100vh;
} */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
  border: none;
  cursor: pointer;
  background: none;
  user-select: none;
  color: inherit;
  font: inherit;
}
button:active {
  outline: none;
}
button:focus {
  outline: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:hover {
  filter: brightness(1.6);
}

@media screen and (min-width: 1299px) {
  :root {
    font-size: 8px;
  }
}

@media screen and (min-width: 1919px) {
  :root {
    font-size: 10px;
  }
}

.hoverUnderline:hover {
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}
